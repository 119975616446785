<template>
  <v-container 
    fluid
    class="gestion-interface-home"
  >
    <template v-if="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </template>
    <template v-else>
      <v-card
          v-for="(menu, i) in filteredMenus"
          :key="'gestion-interface-menu-' + i"
          @click="$router.push(menu.link)"
          min-height="160"
          class="d-flex flex-column align-center justify-center pa-8"
      >
        <img :src="require('@/Assets/Images/Icons/SupportVision/' + menu.icon)" width="64" alt="">
        <span class="title text-center grey--text text--darken-2 mt-4">{{ menu.title }}</span>
      </v-card>
      <v-alert
        v-if="!isLoading && filteredMenus.length === 0"
        width="500px"
        type="error"
        border="left"
        elevation="2"
        colored-border
        class="mt-8"
      >
        Vous n'avez pas les droits pour accéder à cette interface
      </v-alert>
    </template>
  </v-container>
</template>

<script>
import * as Vuex from "vuex";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import DroitService from "@/Services/SupportVision/DroitService";
import LoginService from "@/Services/LoginService";

export default {
  name: "Home",
  computed: {
    ...Vuex.mapState(["informations"]),
    filteredMenus() {   
      if (!this.rights || this.rights.length === 0 || !this.informations) return [];
      
      return this.menus.filter(menu =>
        Array.isArray(this.rights) && this.rights.some(right =>
          (
            right.droit.menus.includes(menu.title) &&
            right.base === this.getDns() &&
            right.droit.users.includes(this.informations.id) 
          ) ||
          (
            right.droit.tag === "SUPERADMIN" &&
            right.droit.menus.includes(menu.title) &&
            right.droit.users.includes(this.informations.id) 
          )
        )
      )  
    }
  }, 
  methods: {
    async saveItemsMenu() {
      await DroitService.postMenuInterface(this.menus)
    },
    getDns() {
      return window.location.hostname;
    },
    async loadRights() {
      this.isLoading = true;
      try {
        const res = await DroitService.getDroit();
        this.rights = res;
      } catch (error) {
        console.error("Error loading rights:", error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  data() {
    return {
      menus: [
        {
          title: "Gestion des messages d'accueil",
          icon: "megaphone.png",
          link: {name: 'SettingsHomepageMessage'}
        },
        {
          title: "Double authentification",
          icon: "double-authentication.png",
          link: {name: 'DoubleAuthentication'}
        },
        {
          title: "Authentification",
          icon: "password.png",
          link: {name: 'SettingsAuthenticationConfiguration'}
        },
        {
          title: "Communication",
          icon: "newsletter.png",
          link: {name: 'SettingsCommunication'}
        },
        {
          title: "Hotline",
          icon: "phone-call.png",
          link: {name: 'SettingsHotline'}
        },
        {
          title: "Clients",
          icon: "user.png",
          link: {name: 'SettingsClients'}
        },
        {
          title: "Bases",
          icon: "database.png",
          link: {name: 'SettingsBases'}
        },
        {
          title: "Fêtes",
          icon: "confeti.png",
          link: {name: 'SettingsParty'}
        },
        {
          title: "Droits",
          icon: "account-cog-outline.svg",
          link: {name: 'SettingsRights'}
        },
        {
          title: "Modules",
          icon: "module.svg",
          link: {name: 'SettingsModules'}
        },
        {
          title: "Diffusion",
          icon: "diffusion.png",
          link: {name: 'SettingsDiffusion'}
        },
        {
          title: "Planning Général",
          icon: "calendar-general.png",
          link: {name: 'SettingsDeveloppement'}
        },
        {
          title: "Cartes",
          icon: "kanban.png",
          link: {name: 'SettingsCartes'}
        },
        {
          title: "Statistiques",
          icon: "statistiques.png",
          link: {name: 'SettingsStatistiques'}
        },
        { 
          title: "Parametrage",
          icon: "parametrage.png",
          link: {name: 'SettingsParametrage'}
        },
        {
          title: "Accueil Développeur",
          icon: "dev-Icon.png",
          link: {name: 'SettingsAccueil_dev'} 
        }
      ],
      rights: [],
      isLoading: true
    }
  },
  async mounted() {
    await this.saveItemsMenu();
    await this.loadRights();
  }
}
</script>

<style scoped>
  .gestion-interface-home {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1.5em;
  }

  @media (max-width: 480px) {
    .gestion-interface-home {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    .gestion-interface-home {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .gestion-interface-home {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    .gestion-interface-home {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media (min-width: 1201px) {
    .gestion-interface-home {
      grid-template-columns: repeat(6, 1fr);
    }
  }
</style>